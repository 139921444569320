import { createRouter, createWebHistory} from "vue-router";


const routes = [
    {
        path: "/inicio",
        name: "home",
        component: ()=> import("../views/HomeView.vue")
    },
    {
        path: "/nosotros",
        name: "nosotros",
        component: () => import("../views/NosotrosView.vue")
    },
    {
        path: "/hoteleria",
        name: "hoteleria",
        component: () => import("../views/HoteleriaView.vue")
    },
    {
        path: "/alimentacion/huespedes",
        name: "alimentacionH",
        component: () => import("../views/AlimentacionHuespedesView.vue")
    },
    {
        path: "/alimentacion/externa",
        name: "alimentacionE",
        component: () => import("../views/AlimentacionExternoView.vue")
    },
    {
        path: "/trabaja",
        name: "trabaja",
        component: () => import("../views/TrabajaView.vue")
    },
    {
        path: "/reglamento",
        name: "reglamento",
        component: () => import("../views/ReglamentoInternoView.vue")
    },
    {
        path: "/reglamento_huespedes",
        name: "reglamento_huespedes",
        component: () => import("../views/ReglamentoHuespedView.vue")
    },
    {
        path: "/",
        redirect: "/inicio",
      },
];

const router = createRouter({
    mode: "history",
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkExactActiveClass: "active",
})


export default router;

